import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Checkbox, Intent, Button} from '@blueprintjs/core';
import { router } from 'App';
import TechnicianPicker from "components/TechnicianPicker";
import { observable, toJS } from 'mobx';
import Toast from 'helpers/toaster';
import AddLocationModal from './AddLocationModal';


@inject('store', 'invoiceStore', 'viewStore') @observer
class InvoiceCustomerDetails extends Component {
  @observable selectedOptions = null;

  onTechnicianChange(technicians) {
    let {
      invoiceStore: {
        invoice: {
          object
        }
      },
      selectedTechnician
    } = this.props;
    let selectedValues = technicians.map(status => status.value);
    selectedTechnician = selectedValues.filter((v) => v !== object.attributes.created_by.id);
    
    if (object.attributes.created_by) {
      this.props.invoiceStore.changeTechnician(object, selectedTechnician[0])
        .then((response) => {  
          Toast.show({ message: "Invoice technician changed successfully.", intent: Intent.SUCCESS })
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            Toast.show({ message: error.response.data.errors, intent: Intent.DANGER })
          } else {
            Toast.show({ message: "Something went wrong. Problem in changing invoice technician.", intent: Intent.DANGER })
          }
        })
    }
  }

  @observable isOpenAddLocationDialog = false;

  handleCloseLocationDailog() {
    this.isOpenAddLocationDialog = false;
  }

  onSuccessAddLocation() {
    this.isOpenAddLocationDialog = false;
  }

  render() {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      },
      viewStore: {
        jobTab: {
          section
        }
      }
    } = this.props;
    let {
      selectedJob,selectedTechnician
    } = this.props;
    return (
      <div className="invoice-customer-details" style={{ display: 'flex', padding: '10px', justifyContent: 'space-between'}}>
        <div className="details-1" style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="technician-details tech-contact-block">
            {/* <div className="tech-label">
              <strong>Client</strong>
            </div> */}
            <div className="tech-contact">
              <div className="tech-name" style={{display:'flex', alignItems:'center'}}>
                <h5><a href={`/dashboard/clients/${object.attributes.client.id}/`} >
                  {object.attributes.client.name}
                </a></h5> <span style={{ marginLeft: 15, color: '#91A4B9' }}>Client</span>
              </div>
              <div className="info-field" style={{lineHeight:1.7}}>
                <div className="more-info long-email-decorator">{object.attributes.client.email}</div>
                <div className="more-info">{object.attributes.client.phone}</div>
                {
                  object.attributes && object.attributes.current_location ?
                    <div className="more-info">{object.attributes.current_location.full_address}</div>
                    :
                    <div className="more-info">{object && object.attributes && object.attributes.location && object.attributes.location.full_address}</div>
                }
              </div>
            </div>
          </span>
        </div>
        <div className="in-right-part">
          {
            object.attributes.job.number &&
            <div className="d-md-flex align-items-start justify-content-between">
              <div className="side-text">Job</div>
              <div className="job-detail-block">
                <a className="job-number-link" href={`/dashboard/jobs/${object.attributes.job.id}/${section}`} >
                  {object.attributes.job.number}
                </a>
                <div className="job-meta-details">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div><span className="font-weight-bold text-muted">Reason for call:</span> {object.attributes.job.title}</div>
                      <div className="job-type-name">{object.attributes.job.service_type_name}</div>
                    </div>
                    {/* <div><Button text="Edit" style={{ height: 20, fontSize: 11 }} onClick={this.startEditJob.bind(this)} /></div> */}
                  </div>
                </div>
              </div>
            </div>
          }
          <div className="d-md-flex align-items-start justify-content-between mt-4">
            <div className="side-text">Technician</div>
            <div className="job-detail-block">
                <TechnicianPicker
                  fill="true"
                  selectedOptions={toJS(selectedTechnician)}
                  onChange={this.onTechnicianChange.bind(this)}
                  includeAdmins={true}
                  hideHeading={true}
                />
              <div className="p-2">
              <div className="more-info mb-1">{object.attributes.created_by.email}</div>
              <div className="more-info">{object.attributes.created_by.phone}</div>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className="details-1 wr-desktop-right-details" style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="technician-details">
            <div className="tech-label">
              <strong>Technician</strong>
            </div>
            <div className="tech-contact">
              <div className="tech-name">
                <a href={`/dashboard/users/${object.attributes.created_by.id}/show`} >
                  { object.attributes.created_by.full_name }
                </a>
              </div>
              <div className="info-field">
                <div className="more-info">{object.attributes.created_by.email}</div>
                <div className="more-info">{object.attributes.created_by.phone}</div>
                <div className="more-info">{object.attributes.client.email}</div>
                <div className="more-info">{object.attributes.client.phone}</div>
                {
                  object.attributes && object.attributes.current_location ?
                  <div className="more-info">{object.attributes.current_location.full_address}</div>
                  :
                  <div className="more-info">{object && object.attributes && object.attributes.location && object.attributes.location.full_address}</div>
                }
                {/* {object && object.attributes && !object.attributes.location_id && 
                  <Button icon='add' className="bp3-button mt-2" onClick={() => {this.isOpenAddLocationDialog = true;} } >Add Location</Button>
                }
              </div>
            </div>
          </span>
        </div>
        {
          object.attributes.job.number && 
            <div className="details-1" style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="technician-details">
                <div className="tech-label">
                  <strong>Job</strong>
                </div>
                <div className="tech-contact">
                  <div className="tech-name">
                    <a href={`/dashboard/jobs/${object.attributes.job.id}/${section}`} >
                    {object.attributes.job.number}
                    </a>
                  </div>
                  <div className="info-field">
                    <div className="more-info">{object.attributes.job.title}</div>
                    <div className="more-info">{object.attributes.job.service_type_name}</div>
                  </div>
                </div>
              </span>
            </div>
        } */}
        {/* <div className="details-2" style={{ display: 'flex', flexDirection: 'column' }}>
          <h6>
            <strong>
              <a className="client-name" href={`/dashboard/clients/${object.attributes.client.id}/`} >
                {object.attributes.client.name}
              </a>
            </strong>
          </h6>
          <span className="clients-address">
            {object.attributes.location.full_address}
          </span>
          {
            object.attributes.po_number && <span className="clients-address">
              {object.attributes.po_number}
            </span>
          }
        </div> */}
        <AddLocationModal
          isOpen={this.isOpenAddLocationDialog}
          onClose={this.handleCloseLocationDailog.bind(this)}
          onSuccess={this.onSuccessAddLocation.bind(this)}
        />
      </div>
    )
  }
}

export default InvoiceCustomerDetails;