import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import {
  Intent,
  Button,
  ButtonGroup,
  Icon,
  Popover,
  Menu,
  MenuItem,
  PopoverInteractionKind,
  Position,
  Tag,
  Alert,
  Collapse, TextArea
} from "@blueprintjs/core";
import { observable, action } from "mobx";
import Toast from "helpers/toaster";
import _ from "lodash";
import { UNITS } from "helpers/units";
import SupplierAutocomplete from "components/SupplierAutocomplete";
import ProductServiceAutocomplete from "components/ProductServiceAutocomplete";
import { currencyFormattedAmount, currencySymbol } from "helpers/currency";

@inject("store", "workReportStore", "viewStore")
@observer
class MaterialItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  @observable showLoader = false;
  @observable isDeleteDialogVisible = false;
  @observable lineitem;
  @observable isOpenSetting = false;
  @observable isOpenSettingIndex = false;

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleClick(event, indexValue) {
    this.isOpenSettingIndex = event;
    if (indexValue == true) {
      this.isOpenSetting = false;
    } else {
      this.isOpenSetting = true;
    }
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.workReportStore
      .deleteLineItem(this.lineitem)
      .then(this.onSuccessfulDeleteMaterial.bind(this))
      .catch(this.onFailDeleteMaterial.bind(this));
  }

  onSuccessfulDeleteMaterial(response) {
    Toast.show({
      icon: "thumbs-up",
      message: "successfully deleted material!",
      intent: Intent.SUCCESS,
    });
    this.lineitem = null;
    this.isDeleteDialogVisible = false;
  }

  onFailDeleteMaterial(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({
      icon: "warning-sign",
      message: "Unable to delete labor!",
      intent: Intent.DANGER,
    });
  }

  handlemMaterialClick(event, lineitem) {
    switch (event.label) {
      case "Delete":
        this.isDeleteDialogVisible = true;
        this.lineitem = lineitem;
        break;
      default:
    }
  }

  componentDidMount() {
    this.calculateMaterial();
  }

  handleInteraction(nextOpenState, material_id) {
    this.props.viewStore.materialLineItemSearchById.where.line_item_id = material_id;
    this.props.workReportStore.loadMaterialLineItemById();
    this.setState({ isOpen: nextOpenState });
  }

  updateLineItem() {
    this.props.workReportStore
      .updateLineItem(this.props.material)
      .then(this.onSuccessfullUpdateLineItem.bind(this))
      .catch(this.onFailUpdateLineItem.bind(this));
  }

  onSuccessfullUpdateLineItem(response) {
    this.props.material.edit = false;
    Toast.show({
      icon: "thumbs-up",
      message: "Updated successfully!",
      intent: Intent.SUCCESS,
    });
  }

  onFailUpdateLineItem(error) {
    Toast.show({
      icon: "warning-sign",
      message: "Unable to update!",
      intent: Intent.DANGER,
    });
  }

  handlePartNumberChange(event) {
    let { material } = this.props;
    material.part_number = event.target.value;
  }

  handleHoursChange(event) {
    let { material } = this.props;
    material.quantity_display_value =
      event.target.value === "" ? 0 : event.target.value;
    material.quantity = parseFloat(material.quantity_display_value);
    this.calculateMaterial();
  }

  handleDotInNumericField(event) {
    // if(event.key === '.') {
    //   event.preventDefault();
    // }
  }

  handleHourlyRateChange(event) {
    let { material } = this.props;
    var value =
      event.target.value === "" ? 0 : event.target.value.replace("$", "");
    material.rate_per_unit = value;
    material.rate_per_unit_display_value = currencyFormattedAmount(
      material.rate_per_unit
    );
    this.calculateMaterial();
  }

  handleMarkupChange(event) {
    let {material} = this.props;
    material.markup_override = true
    material.markup = event.target.value === "" ? 1 : parseFloat(event.target.value)
    this.calculateMaterial();
  }

  calculateMaterial() {
    let { material } = this.props;
    if (material.markup) {
      material.total =
        parseFloat(material.quantity) *
        parseFloat(material.rate_per_unit) *
        parseFloat(material.markup);
    } else {
      material.total =
        parseFloat(material.quantity) * parseFloat(material.rate_per_unit);
    }
    material.total_display_value = currencyFormattedAmount(material.total);

    let {
      workReportStore: {
        workReport: { object },
      },
    } = this.props;

    if (object.attributes.custom_line_items.length > 0) {
      _.each(object.attributes.custom_line_items, function (lineItem) {
        if (
          lineItem.default_line_item_type === "percentage_of_material" &&
          !lineItem.override
        ) {
          // rate update
          lineItem.rate_per_unit =
            (_.sumBy(object.attributes.materials, "total") *
              lineItem.default_line_item_value) /
            100;
          lineItem.rate_per_unit_display_value = currencyFormattedAmount(
            lineItem.rate_per_unit
          );
        }

        if (
          lineItem.default_line_item_type ===
          "percentage_of_materials_labour" &&
          !lineItem.override
        ) {
          // rate update
          lineItem.rate_per_unit =
            ((_.sumBy(object.attributes.labour, "total") +
              _.sumBy(object.attributes.materials, "total")) *
              lineItem.default_line_item_value) /
            100;
          lineItem.rate_per_unit_display_value = currencyFormattedAmount(
            lineItem.rate_per_unit
          );
        }

        // total update
        if (lineItem.markup) {
          lineItem.total =
            parseFloat(lineItem.quantity) *
            parseFloat(lineItem.rate_per_unit) *
            parseFloat(lineItem.markup);
        } else {
          lineItem.total =
            parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit);
        }
        lineItem.total_display_value = currencyFormattedAmount(lineItem.total);
      });
    }
  }

  @action
  onSupplierSelect(materialDetail) {
    let { material } = this.props;

    if (materialDetail) {
      material.supplier_id = materialDetail.id;
      material.supplier = materialDetail;
    } else {
      material.supplier_id = null;
      material.supplier = null;
    }
  }

  @action
  onProductSelect(materialDetail) {
    let { material } = this.props;

    if (materialDetail) {
      material.product_service_id = materialDetail.id;
      material.product_service = materialDetail;
    } else {
      material.product_service_id = null;
      material.product_service = null;
    }
  }

  updateSelectedResource() {
    let { material } = this.props;

    if (material.description == null) {
      Toast.show({
        icon: "warning-sign",
        message: "Please enter description!",
        intent: Intent.DANGER,
      });
      return;
    }
    if (material.supplier == null) {
      Toast.show({
        icon: "warning-sign",
        message: "Please enter supplier!",
        intent: Intent.DANGER,
      });
      return;
    }
    if (material.part_number == null) {
      Toast.show({
        icon: "warning-sign",
        message: "Please enter part number!",
        intent: Intent.DANGER,
      });
      return;
    }
    if (material.quantity === 0) {
      Toast.show({
        icon: "warning-sign",
        message: "Please enter quantity!",
        intent: Intent.DANGER,
      });
      return;
    }
    if (material.rate_per_unit == null) {
      Toast.show({
        icon: "warning-sign",
        message: "Please enter unit price!",
        intent: Intent.DANGER,
      });
      return;
    }

    this.showLoader = true;
    this.props.workReportStore
      .update()
      .then(this.props.workReportStore.addMaterialLineItem(material))
      .then(this.onSucessfullAddMaterialLineItem.bind(this))
      .catch(this.FailAddMaterialLineItem.bind(this));
  }

  onSucessfullAddMaterialLineItem(response) {
    this.showLoader = false;
    Toast.show({
      icon: "thumbs-up",
      message: "Material line item added successfully!",
      intent: Intent.SUCCESS,
    });
  }

  FailAddMaterialLineItem(error) {
    this.showLoader = false;
    Toast.show({
      icon: "warning-sign",
      message: "Failed to add material line item",
      intent: Intent.DANGER,
    });
  }

  handleUnitChange(event) {
    let { material } = this.props;
    material.unit = event.target.value;
    this.calculateMaterial();
  }

  getStatusColorCode(status) {
    var colorCode = "text-capitalize";
    switch (status) {
      case "pending order":
        return colorCode + " color-pending";
      case "ordered":
        return colorCode + " color-ordered";
      case "in transit":
        return colorCode + " color-intransit";
      case "received":
        return colorCode + " color-recieved";
      case "picked up by the technician":
        return colorCode + " color-pubt";
      case "archived":
        return colorCode + " color-archived";
      default:
        return colorCode + " color-default";
    }
  }

  render() {
    let { material } = this.props;

    let { objects } = this.props.workReportStore.materialLineItem;

    let popoverContent = (
      <table className="table report-data-table">
        <thead>
          <tr>
            <th>Description</th>
            <th>Quantity</th>
            <th>Status</th>
            <th>PO Number</th>
            <th>Supplier</th>
          </tr>
        </thead>
        <tbody>
          {objects &&
            objects.length > 0 &&
            objects.map((items) => {
              return (
                <tr key={Math.random()}>
                  <td>{items.attributes.description}</td>
                  <td>{items.attributes.quantity}</td>
                  <td>
                    <Tag
                      className={this.getStatusColorCode(
                        items.attributes.status
                      )}
                    >
                      {items.attributes.status}
                    </Tag>
                  </td>
                  <td>
                    {items.attributes.purchase_order.number ? (
                      <a
                        href={`/dashboard/purchase-orders/${items.attributes.purchase_order.id}/show-purchase-order`}
                        className="bp3-menu-item bp3-intent-default bp3-popover-dismiss"
                      >
                        {items.attributes.purchase_order.number}
                      </a>
                    ) : (
                        ""
                      )}
                  </td>
                  <td>
                    <a
                      href={`/dashboard/suppliers/${items.attributes.supplier.id}/show`}
                      className="bp3-menu-item bp3-intent-default bp3-popover-dismiss"
                    >
                      {items.attributes.supplier.name}
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );

    return (
      <React.Fragment>
        {/* <tr key={material.id}>
        <td colSpan="2" width="18%">
          <ProductServiceAutocomplete
            value={material.product_service}
            onProductSelect={this.onProductSelect.bind(this)} 
          />
        </td>
        <td colSpan="7"></td>
      </tr>
      <tr key={material.id}>
        <td colSpan="2">
          <label className="only-mobile">Description</label>
          <input 
            type='text' 
            className='bp3-input bp3-fill' 
            placeholder='Description'
            defaultValue={material.description}
            onChange={(event) => {material.description = event.target.value;}} 
          />
        </td>
        <td width="130px">         
          <SupplierAutocomplete
            value={material.supplier}
            onSupplierSelect={this.onSupplierSelect.bind(this)} 
          />
        </td>
        <td width="130px">
          <label className="only-mobile">Part Number</label>
          <input 
            type='text' 
            className='bp3-input bp3-fill' 
            placeholder='Part number'
            defaultValue={material.part_number}
            onChange={(event) => this.handlePartNumberChange(event)} 
          />
        </td>
        <td width="110px">
          <label className="only-mobile">Qty</label>
          <input 
            type='number' 
            step={1}
            className='bp3-input bp3-fill' 
            placeholder='Qty'
            style={{paddingRight:0}}
            defaultValue={parseFloat(material.quantity) || 0}
            onChange={(event) => this.handleHoursChange(event) }
            onKeyDown={this.handleDotInNumericField.bind(this)}
          />  
        </td>
        <td width="120px">
          <label className="only-mobile">Unit Price({currencySymbol})</label>
          <input 
            type='number' 
            className='bp3-input bp3-fill' 
            placeholder='Unit Price ($)'
            defaultValue={material.rate_per_unit || ''}
            onChange={(event) => this.handleHourlyRateChange(event) }
          />    
        </td>
          <td width="70px">
          <label className="only-mobile">Markup</label>
          <input 
            type='number' 
            className='bp3-input bp3-fill' 
            placeholder='Markup'
            defaultValue={material.markup || 1}
            onChange={(event) => this.handleMarkupChange(event) }
          />
        </td>
        <td>
          <label className="only-mobile">Total</label>
          {currencyFormattedAmount(material.total)}
        </td>
        <td style={{ width: '2%' }}>
          <button 
            className='bp3-button bp3-minimal bp3-icon-trash bp3-small' 
            type='button'
            onClick={() => this.handlemMaterialClick({ label: 'Delete' }, material)} />
        </td>
        <td >
          { material.material_line_items.length > 0
           ?  
              <Popover
                content={popoverContent}
                interactionKind={PopoverInteractionKind.HOVER}
                isOpen={this.state.isOpen}
                onInteraction={(state) => this.handleInteraction(state,material.id)}
                position={Position.BOTTOM_RIGHT}
              >
                <Tag className={this.getStatusColorCode(material.material_line_items[0].status)}>{material.material_line_items[0].status}</Tag>
              </Popover> 
            :
              <Button icon="plus" className="add-to-list-btn" text="Queue" onClick= {this.updateSelectedResource.bind(this)} loading={this.showLoader} />
            }

          </td>
         
      </tr>  */}
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>Warnning! Deleting this material will not be recoverable.</p>
          <p>Are you sure you want to do this?</p>
        </Alert>


        <tr key={material.id}>
          <td className="p-0 border-left-0">
            <ButtonGroup
              fill={true}
              alignText="left"
              large={true}
              minimal
            >
              <ProductServiceAutocomplete
                value={material.product_service}
                onProductSelect={this.onProductSelect.bind(this)}
              />
            </ButtonGroup>
          </td>
          <td className="p-0">
            <ButtonGroup
              fill={true}
              alignText="left"
              large={true}
              minimal
            >
              <SupplierAutocomplete
                value={material.supplier}
                onSupplierSelect={this.onSupplierSelect.bind(this)}
              />
            </ButtonGroup>
          </td>
          <td className="p-0">
            <input
              type="text"
              className="bp3-input bp3-fill"
              placeholder="Part number"
              defaultValue={material.part_number}
              onChange={(event) => this.handlePartNumberChange(event)}
            />
          </td>
          <td className="p-0">
            <input
              type="number"
              step={1}
              className="bp3-input bp3-fill"
              placeholder="Qty"
              style={{ paddingRight: 0 }}
              defaultValue={parseFloat(material.quantity) || 0}
              onChange={(event) => this.handleHoursChange(event)}
              onKeyDown={this.handleDotInNumericField.bind(this)}
            />
          </td>
          <td className="p-0">
            <div className="bp3-select bp3-fill bp3-large">
              <select onChange={(event) => this.handleUnitChange(event)} value={material.unit}>
                <option value="">N/A</option>
                {
                  Object.keys(UNITS).map((key) => {
                    return (
                      <optgroup label={UNITS[key].label}>
                        {
                          UNITS[key].options.map((option) => {
                            return <option value={option.value}>{option.label}</option>
                          })
                        }
                      </optgroup>
                    )
                  })
                }
              </select>
            </div>
          </td>
          <td className="p-0">
            <input
              type="number"
              className="bp3-input bp3-fill"
              placeholder="Unit Price ($)"
              defaultValue={material.rate_per_unit || ""}
              onChange={(event) => this.handleHourlyRateChange(event)}
            />
          </td>
          <td className="p-0">
            <input
              type="number"
              className="bp3-input bp3-fill"
              placeholder="Markup"
              defaultValue={material.markup || 1}
              onChange={(event) => this.handleMarkupChange(event)}
            />
          </td>
          <td className="py-0" style={{ textAlign: 'right' }}>
            <strong>{currencyFormattedAmount(material.total)}</strong>
          </td>
          <td rowSpan="2" vAlign="middle" className="border-0">
            <Button
              minimal
              type='button'
              icon={"cross"}
              onClick={() => this.handlemMaterialClick({ label: 'Delete' }, material)}
              className="row-delete-btn"
            />
          </td>
        </tr>
        <tr>
          <td colSpan="7" className="p-0 border-left-0">
            <TextArea
              growVertically={true}
              className="bp3-input bp3-fill"
              placeholder="Description"
              defaultValue={material.description}
              onChange={(event) => {
                material.description = event.target.value;
              }}
            />
          </td>
          <td>
            {material.material_line_items.length > 0
              ?
              <Popover
                content={popoverContent}
                interactionKind={PopoverInteractionKind.HOVER}
                isOpen={this.state.isOpen}
                onInteraction={(state) => this.handleInteraction(state, material.id)}
                position={Position.BOTTOM_RIGHT}
              >
                <Tag className={this.getStatusColorCode(material.material_line_items[0].status)}>{material.material_line_items[0].status}</Tag>
              </Popover>
              :
              <Button minimal icon="plus" className="add-to-list-btn" text="Queue" onClick={this.updateSelectedResource.bind(this)} loading={this.showLoader} style={{ color: '#1800FF', fontWeight: 'bold' }} />
            }
          </td>
        </tr>

        <div style={{ height: 10 }}></div>



        {/* <tr key={Math.random()}>
          <td colSpan="2" width="18%">
            <ProductServiceAutocomplete
              value={material.product_service}
              onProductSelect={this.onProductSelect.bind(this)} 
            />
          </td>
          <td colSpan="7"></td>
        </tr>
        <tr key={material.id}>
          <td colSpan="2">
            <label className="only-mobile">Description</label>
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Description'
              defaultValue={material.description}
              onChange={(event) => {material.description = event.target.value;}} 
            />
          </td>
          <td width="130px">         
            <SupplierAutocomplete
              value={material.supplier}
              onSupplierSelect={this.onSupplierSelect.bind(this)} 
            />
          </td>
          <td width="130px">
            <label className="only-mobile">Part Number</label>
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Part number'
              defaultValue={material.part_number}
              onChange={(event) => this.handlePartNumberChange(event)} 
            />
          </td>
          <td width="110px">
            <label className="only-mobile">Qty</label>
            <input 
              type='number' 
              step={1}
              className='bp3-input bp3-fill' 
              placeholder='Qty'
              style={{paddingRight:0}}
              defaultValue={parseFloat(material.quantity) || 0}
              onChange={(event) => this.handleHoursChange(event) }
              onKeyDown={this.handleDotInNumericField.bind(this)}
            />  
          </td>
          <td width="120px">
            <label className="only-mobile">Unit Price({currencySymbol})</label>
            <input 
              type='number' 
              className='bp3-input bp3-fill' 
              placeholder='Unit Price ($)'
              defaultValue={material.rate_per_unit || ''}
              onChange={(event) => this.handleHourlyRateChange(event) }
            />    
          </td>
          <td width="100px">
            <label className="only-mobile">Markup</label>
            <input 
              type='number' 
              className='bp3-input bp3-fill' 
              placeholder='Markup'
              defaultValue={material.markup || 1}
              onChange={(event) => this.handleMarkupChange(event) }
            />
          </td>
          <td>
            <label className="only-mobile">Total</label>
            {currencyFormattedAmount(material.total)}
          </td>
          <td style={{ width: '2%' }}>
            <button 
              className='bp3-button bp3-minimal bp3-icon-trash bp3-small' 
              type='button'
              onClick={() => this.handlemMaterialClick({ label: 'Delete' }, material)} />
          </td>
          <td >
            { material.material_line_items.length > 0
            ?  
                <Popover
                  content={popoverContent}
                  interactionKind={PopoverInteractionKind.HOVER}
                  isOpen={this.state.isOpen}
                  onInteraction={(state) => this.handleInteraction(state,material.id)}
                  position={Position.BOTTOM_RIGHT}
                >
                  <Tag className={this.getStatusColorCode(material.material_line_items[0].status)}>{material.material_line_items[0].status}</Tag>
                </Popover> 
              :
                <Button icon="plus" className="add-to-list-btn" text="Queue" onClick= {this.updateSelectedResource.bind(this)} loading={this.showLoader} />
              }

            </td>
            <Alert
              cancelButtonText="Cancel"
              confirmButtonText="Delete"
              icon="trash"
              intent={Intent.DANGER}
              isOpen={this.isDeleteDialogVisible}
              onCancel={this.handleDeleteCancel.bind(this)}
              onConfirm={this.handleDeleteConfirm.bind(this)}
            >
              <p>Warnning! Deleting this material will not be recoverable.</p>
              <p>Are you sure you want to do this?</p>
          </Alert>
        </tr>  */}
      </React.Fragment>

    )
  }
}

export default MaterialItem;
