import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { PaymentBanner } from "components/Styles";
import moment from "moment";
import { Button } from "@blueprintjs/core";
import { observable } from "mobx";
import QuoteAcceptModal from "./QuoteAcceptModal";
import { currencyFormattedAmount } from 'helpers/currency';

@inject("quoteStore")
@observer
class QuoteHeader extends Component {

  @observable isOpenQuoteAcceptDialog = false;
  @observable isButtonLoading = false;

  handleCloseQuoteAcceptDailog() {
    this.isOpenQuoteAcceptDialog = false;
  }

  onSuccessQuoteAccept() {
    this.isButtonLoading = false;
    this.isOpenQuoteAcceptDialog = false;
    window.location.reload(true)
  }

  acceptQuote() {
    const {
      quoteStore: {
        quote: { object }
      }
    } = this.props;
    let quote_accept = {
      quote_id: object.id,
      accepted: true
    }

    this.isButtonLoading = true;

    this.props.quoteStore.quoteAccept(quote_accept)
      .then(() => {
        this.onSuccessQuoteAccept();
        this.props.onSuccess();
      })
  }

  startApproveProcess() {
    const {
      quoteStore,
      quoteStore: {
        quote: { object }
      }
    } = this.props;
    if(object.attributes && object.attributes.enable_invoice_signature) {
      this.isOpenQuoteAcceptDialog = true;
    } else {
      this.acceptQuote();
    }
  }

  buttonText () {
    const {
      quoteStore,
      quoteStore: {
        quote: { object }
      }
    } = this.props;
    if (object?.attributes?.is_accepted) {
      return `Approved estimate for ${currencyFormattedAmount(object.attributes.grand_total)}`;
    } else if(object?.attributes?.enable_invoice_signature) {
      return `Sign and Approve estimate for ${currencyFormattedAmount(object.attributes.grand_total)}`;
    }
    return `Approve estimate for ${currencyFormattedAmount(object.attributes.grand_total)}`;
  }

  render() {
    const {
      quoteStore: {
        quote: { object }
      }
    } = this.props;
    return (
      <div>
        <div className="payment-header review-payment-header">
          <div>
            <h4>
            Review and approve estimate from {" "}
              {object && object.attributes && object.attributes.tenant.name}
            </h4>
          </div>
          <div className="review-payment-button">
            <Button text="Print" icon="print" style={{ marginRight: 15 }} onClick={() => window.print()} />
            {object && object.attributes && !object.attributes.is_accepted && <Button text={this.buttonText()} icon="credit-card" intent="primary" loading={this.isButtonLoading} onClick={this.startApproveProcess.bind(this)}></Button>}
            {object && object.attributes && object.attributes.is_accepted && <Button text={this.buttonText()} icon="credit-card" intent="primary" loading={this.isButtonLoading} disabled={true}></Button>}
          </div>
        </div>
        <QuoteAcceptModal
          isOpen={this.isOpenQuoteAcceptDialog}
          onClose={this.handleCloseQuoteAcceptDailog.bind(this)}
          onSuccess={this.onSuccessQuoteAccept.bind(this)}
        />
      </div>
    );
  }
}

export default QuoteHeader;
