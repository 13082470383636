import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { currencyFormattedAmount } from "helpers/currency";
import { thumbnailSrcImageForImage } from "helpers/strings";
import { fileNameFromUri } from "helpers/strings";
import { toJS, observable } from "mobx";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

@inject("quoteStore")
@observer
class QuoteLineItems extends Component {
  @observable isLightboxOpen = false;
  @observable currentImage = 0;
  @observable imageSet = [];

  setImages() {
    const {
			quoteStore: {
				quote: { object },
			},
		} = this.props;

    this.images = object.attributes.images;

    this.imageSet = [];

    if (this.images.length > 0) {
      this.images.map((image) => {
        const thumbnail = thumbnailSrcImageForImage(image.asset);
        console.log("image: ", image.asset)
        console.log("thumbnail: ", thumbnail)
        this.imageSet.push({
          src: image.asset.url,
          thumbnail,
          id: image.id,
        });
        return this.imageSet;
      });
    }
  }

  closeLightbox = () => {
    this.isLightboxOpen = false;
  }

  openLightbox = (index) => {
    this.setImages();
    this.currentImage = index;
    this.isLightboxOpen = true;
  }

	render() {
		const {
			quoteStore: {
				quote: { object },
			},
		} = this.props;

		return (
			<React.Fragment>
				{object.attributes.flat_rate === false &&
					(object.attributes.labour.length ||
						object.attributes.materials.length ||
						object.attributes.custom_line_items.length) > 0 && (
						<div className="payment-padding" style={{ marginTop: -80 }}>
							{" "}
							<table
								className="table table-borderless"
								style={{ width: "100%" }}
							>
								<thead>
									<tr>
										<th width="50%" style={{ color: "#91A4B9", fontSize: 12 }}>
											Description
										</th>
										<th width="10%" style={{ color: "#91A4B9", fontSize: 12 }}>
											Hours/Qty
										</th>
										<th width="25%" style={{ color: "#91A4B9", fontSize: 12 }}>
											Hourly Rate/Unit Price
										</th>
										<th style={{ color: "#91A4B9", fontSize: 12 }}>Amount</th>
									</tr>
								</thead>
								<tbody>
									{object.attributes.labour.map((labour) => {
										if (labour.total > 0) {
											return (
												<tr key={labour.id}>
													<td
														width="50%"
														style={{
															height: "auto",
															borderColor: "#fff",
															borderTopWidth: 5,
														}}
													>
														{labour.description}
													</td>
													<td
														width="10%"
														style={{
															height: "auto",
															borderColor: "#fff",
															borderTopWidth: 5,
														}}
													>
														{labour.quantity}
													</td>
													<td
														width="25%"
														style={{
															height: "auto",
															borderColor: "#fff",
															borderTopWidth: 5,
														}}
													>
														{currencyFormattedAmount(labour.rate_per_unit)}{" "}
													</td>
													<td
														style={{
															height: "auto",
															borderColor: "#fff",
															borderTopWidth: 5,
														}}
													>
														{currencyFormattedAmount(labour.total)}
													</td>
												</tr>
											);
										} else {
											return null;
										}
									})}
									{object.attributes.materials.length > 0 &&
										object.attributes.materials.map((material) => {
											if (material.total > 0) {
												return (
													<tr key={material.id}>
														<td
															width="50%"
															style={{
																height: "auto",
																borderColor: "#fff",
																borderTopWidth: 5,
															}}
														>
															{material.description}
														</td>
														<td
															width="10%"
															style={{
																height: "auto",
																borderColor: "#fff",
																borderTopWidth: 5,
															}}
														>
															{material.quantity}
														</td>
														<td
															width="25%"
															style={{
																height: "auto",
																borderColor: "#fff",
																borderTopWidth: 5,
															}}
														>
															{currencyFormattedAmount(material.rate_per_unit)}
														</td>
														<td
															style={{
																height: "auto",
																borderColor: "#fff",
																borderTopWidth: 5,
															}}
														>
															{currencyFormattedAmount(material.total)}
														</td>
													</tr>
												);
											} else {
												return null;
											}
										})}
									{object.attributes.custom_line_items.length > 0 &&
										object.attributes.custom_line_items.map((lineitem) => {
											if (lineitem.total > 0) {
												return (
													<tr key={lineitem.id}>
														<td
															width="50%"
															style={{
																height: "auto",
																borderColor: "#fff",
																borderTopWidth: 5,
																backgroundColor: "#F6F6F6",
															}}
														>
															{lineitem.description}
														</td>
														<td
															width="10%"
															style={{
																height: "auto",
																borderColor: "#fff",
																borderTopWidth: 5,
																backgroundColor: "#F6F6F6",
															}}
														>
															{lineitem.quantity}
														</td>
														<td
															width="25%"
															style={{
																height: "auto",
																borderColor: "#fff",
																borderTopWidth: 5,
																backgroundColor: "#F6F6F6",
															}}
														>
															{currencyFormattedAmount(lineitem.rate_per_unit)}
														</td>
														<td
															style={{
																height: "auto",
																backgroundColor: "#F6F6F6",
																borderColor: "#fff",
																borderTopWidth: 5,
															}}
														>
															{currencyFormattedAmount(lineitem.total)}
														</td>
													</tr>
												);
											} else {
												return null;
											}
										})}
								</tbody>
							</table>
							<hr />
						</div>
					)}
				<div className="payment-padding">
					<table style={{ width: "100%" }}>
						<tbody>
							<tr>
								<td width="50%"></td>
								<td>
									<table style={{ width: "100%" }}>
										<tbody>
											<tr>
												<td align="right">
													<div
														style={{
															marginBottom: 10,
															marginRight: 0,
															color: "#91A4B9",
														}}
													>
														Sub Total:
													</div>
												</td>
												<td width="21%" align="right">
													<div style={{ marginBottom: 10 }}>
														{currencyFormattedAmount(
															object.attributes.subtotal,
														)}
													</div>
												</td>
											</tr>
											{object &&
												object.attributes.taxes.length > 0 &&
												object.attributes.taxes.map((obj) => {
													if (obj.total > 0) {
														return (
															<tr key={Math.random()}>
																<td width="67%" align="right">
																	<div
																		style={{
																			marginBottom: 10,
																			marginRight: 0,
																			color: "#91A4B9",
																		}}
																	>
																		{obj.name} {obj.tax_percentage_value}%:
																	</div>
																</td>
																<td align="right">
																	<div style={{ marginBottom: 10 }}>
																		{currencyFormattedAmount(obj.total)}
																	</div>
																</td>
															</tr>
														);
													} else {
														return null;
													}
												})}
											<tr>
												<td align="right">
													<div
														style={{
															marginBottom: 10,
															marginRight: 0,
															color: "#91A4B9",
														}}
													>
														Total:
													</div>
												</td>
												<td width="21%" align="right">
													<div style={{ marginBottom: 10 }}>
														{currencyFormattedAmount(
															object.attributes.grand_total,
														)}
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<br />

				{object.attributes.images.length > 0 && object.attributes?.tenant?.show_attachments_on_estimates_public_link ? (
					<div className="payment-padding">
						<h6 style={{ color: "#91A4B9" }}>Images</h6>
						<div style={{ flex: 1, display: "flex", flexWrap: "wrap" }}>
              {object.attributes.images.length > 0 &&
                object.attributes.images.map((image, index) => {
                  const thumbnail = thumbnailSrcImageForImage(image.asset);
                  const fileName = fileNameFromUri(image.asset.url);
                  return (
                    <div key={fileName} className="wr-img-wrap" style={{ display: "flex", marginRight: 10, flexDirection: "column", maxWidth: 155, overflow: "hidden" }}>
                      <img
                        src={thumbnail}
                        alt="Attached with Estimate"
                        key={fileName}
                        onClick={() => this.openLightbox(index)}
                        className="wr-img"
                      />
                      <p style={{textOverflow: "ellipsis", overflow: "hidden"}}>{fileName}</p>
                    </div>
                  );
                })}
            </div>
					</div>
				) : null}
        <Lightbox
          open={this.isLightboxOpen}
          close={this.closeLightbox.bind(this)}
          slides={toJS(this.imageSet)}
          plugins={[Zoom, Thumbnails]}
          index={this.currentImage}
          zoom={{
            maxZoomPixelRatio: 10,
            zoomInMultiplier: 1.5,
          }}
        />
				{object.attributes.description ? (
					<div className="payment-padding">
						<h6 style={{ color: "#91A4B9" }}>Customer Notes</h6>
						<div style={{ marginBottom: 40 }}>
							<div
								dangerouslySetInnerHTML={{
									__html: object.attributes.description,
								}}
							/>
						</div>
					</div>
				) : null}
				{object.attributes.agreement_copy ? (
					<div className="payment-padding">
						<h6 style={{ color: "#91A4B9" }}>Agreement</h6>
						<div style={{ marginBottom: 40 }}>
							<div
								dangerouslySetInnerHTML={{
									__html: object.attributes.agreement_copy,
								}}
							/>
						</div>
					</div>
				) : null}
			</React.Fragment>
		);
	}
}

export default QuoteLineItems;
