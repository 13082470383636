import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import _ from 'lodash';
import { Intent } from '@blueprintjs/core';
import Toast from 'helpers/toaster';
import { currencyFormattedAmount } from 'helpers/currency';

@inject('store', 'quoteStore', 'taxStore') @observer
class MaterialTaxItem extends Component {

  @observable totalTax = 0;
  @observable selectedTaxId = '';

  componentDidMount() {
    this.calculateTax();
  }

  componentWillReceiveProps(nextProps) {
    const {
      taxStore: {
        taxes: {
          objects
        }
      }
    } = this.props;
    if (nextProps.material) {
      let taxLineItem = _.find(objects, { attributes: { id: this.selectedTaxId } })
      if (taxLineItem && taxLineItem.attributes) {
        this.totalTax = currencyFormattedAmount((taxLineItem.attributes.percentage_value / 100) * nextProps.material.total)
      }
    }
  }

  calculateTax() {
    const {
      material,
      taxItem
    } = this.props;
    this.selectedTaxId = taxItem.tax_id
    this.totalTax = currencyFormattedAmount((taxItem.percentage_value / 100) * material.total)
  }

  calculateTotalTax(event) {
    const {
      material,
      taxItem,
      taxStore,
      taxStore: {
        taxes: {
          objects
        }
      }
    } = this.props;

    let taxLineItem = _.find(objects, { attributes: { id: event.target.value } })

    taxStore.updateMaterialTaxLineItem(taxItem, taxLineItem)
      .then((res) => {
        this.selectedTaxId = taxLineItem.attributes.id
        this.totalTax = currencyFormattedAmount((taxLineItem.attributes.percentage_value / 100) * material.total)
        this.props.refresh()
      })

  }

  deleteTaxLineItem() {
    // const {
    //   invoiceStore,
    //   taxItem
    // } = this.props;
    // invoiceStore.deleteMaterialTaxLineItem(taxItem)
    //   .then(this.onSuccessfulDeleteTaxLineItem.bind(this))
    //   .catch(this.onFailDeleteTaxLineItem.bind(this))
  }

  onSuccessfulDeleteTaxLineItem(response) {

  }

  onFailDeleteTaxLineItem(error) {
    Toast.show({ icon: 'warning-sign', message: error.response.data.errors, intent: Intent.DANGER })
  }

  render() {
    const {
      taxItem,
      taxStore: {
        taxes: {
          objects
        }
      }
    } = this.props;

    return (
      <React.Fragment>

        <tr key={taxItem.id}>
          <td colSpan="6" className="py-0 border-left-0"></td>
          <td className="p-0">
            <div className="bp3-select bp3-fill bp3-large">
              <select onChange={(event) => { this.calculateTotalTax(event) }} value={this.selectedTaxId}>
                {
                  objects.length > 0 && objects.map((tanent_tax) => {
                    return (
                      <option value={tanent_tax.attributes.id} key={tanent_tax.attributes.id}>{tanent_tax.attributes.name} {tanent_tax.attributes.percentage_value}%</option>
                    )
                  })
                }
              </select>
            </div>
          </td>
          <td className="py-0">{this.totalTax}</td>
        </tr>

      </React.Fragment>
    )
  }

};

export default MaterialTaxItem;