import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Intent,
  Alert,
  Button,
  ButtonGroup,
  TextArea,
} from '@blueprintjs/core';
import { observable, action } from 'mobx';
import Toast from 'helpers/toaster';
import SupplierAutocomplete from 'components/SupplierAutocomplete';
import _ from 'lodash';
import { currencyFormattedAmount } from 'helpers/currency';
import MaterialTaxItem from './MaterialTaxItem';
import ProductServiceAutocomplete from 'components/ProductServiceAutocomplete';
import { UNITS } from 'helpers/units';

@inject('store', 'quoteStore') @observer
class MaterialItem extends Component {
  @observable isDeleteDialogVisible = false;
  @observable lineitem;

  componentDidMount() {
    let { material } = this.props;
    if (material.markup) {
      material.total = parseFloat(material.quantity) * parseFloat(material.rate_per_unit) * parseFloat(material.markup)
    } else {
      material.total = parseFloat(material.quantity) * parseFloat(material.rate_per_unit)
    }
  }

  handleDeleteCancel() {
    this.isDeleteDialogVisible = false;
  }

  handleDeleteConfirm() {
    this.isDeleteDialogVisible = false;
    this.props.quoteStore.deleteLineItem(this.lineitem)
      .then(this.onSuccessfulDeleteMaterial.bind(this))
      .catch(this.onFailDeleteMaterial.bind(this))
  }

  onSuccessfulDeleteMaterial(response) {
    Toast.show({ icon: 'thumbs-up', message: 'successfully deleted material!', intent: Intent.SUCCESS })
    this.lineitem = null;
    this.isDeleteDialogVisible = false;
  }

  onFailDeleteMaterial(error) {
    this.isDeleteDialogVisible = false;
    Toast.show({ icon: 'warning-sign', message: 'Unable to delete labor!', intent: Intent.DANGER })
  }

  handlemMaterialClick(event, lineitem) {
    switch (event.label) {
      case 'Delete':
        this.isDeleteDialogVisible = true;
        this.lineitem = lineitem;
        break;
      default:
    }
  }

  handlePartNumberChange(event) {
    let { material } = this.props;
    material.part_number = event.target.value;
  }

  @action
  onSupplierSelect(materialDetail) {
    const {
      material
    } = this.props;

    if (materialDetail) {
      material.supplier_id = materialDetail.id
      material.supplier = materialDetail
    } else {
      material.supplier_id = null
      material.supplier = null
    }
  }

  @action
  onProductSelect(materialDetail) {
    let {
      material
    } = this.props;

    if (materialDetail) {
      material.product_service_id = materialDetail.id
      material.product_service = materialDetail
    } else {
      material.product_service_id = null
      material.product_service = null
    }
  }

  handleHoursChange(event) {
    let { material } = this.props;
    material.quantity_display_value = event.target.value === "" ? 0 : event.target.value
    material.quantity = parseFloat(material.quantity_display_value)
    this.calculateMaterial();
  }

  handleHourlyRateChange(event) {
    let { material } = this.props;
    var value = event.target.value === "" ? 0 : event.target.value.replace('$', '')
    material.rate_per_unit = value;
    material.rate_per_unit_display_value = currencyFormattedAmount(material.rate_per_unit)
    this.calculateMaterial();
  }

  handleMarkupChange(event) {
    let { material } = this.props;
    material.markup_override = true;
    material.markup = event.target.value === "" ? 1 : parseFloat(event.target.value)
    this.calculateMaterial();
  }

  handleUnitChange(event) {
    let { material } = this.props;
    material.unit = event.target.value;
    this.calculateMaterial();
  }

  calculateMaterial() {
    let { material } = this.props;
    if (material.markup) {
      material.total = parseFloat(material.quantity) * parseFloat(material.rate_per_unit) * parseFloat(material.markup)
    } else {
      material.total = parseFloat(material.quantity) * parseFloat(material.rate_per_unit)
    }
    material.total_display_value = currencyFormattedAmount(material.total)

    let {
      quoteStore: {
        quote: {
          object
        }
      }
    } = this.props;

    if (object.attributes.custom_line_items.length > 0) {
      _.each(object.attributes.custom_line_items, function (lineItem) {
        if (lineItem.default_line_item_type === "percentage_of_material" && !lineItem.override) {
          // rate update
          lineItem.rate_per_unit = _.sumBy(object.attributes.materials, 'total') * lineItem.default_line_item_value / 100;
          lineItem.rate_per_unit_display_value = currencyFormattedAmount(lineItem.rate_per_unit)
        }

        if (lineItem.default_line_item_type === "percentage_of_materials_labour" && !lineItem.override) {
          // rate update
          lineItem.rate_per_unit = (_.sumBy(object.attributes.labour, 'total') + _.sumBy(object.attributes.materials, 'total')) * lineItem.default_line_item_value / 100;
          lineItem.rate_per_unit_display_value = currencyFormattedAmount(lineItem.rate_per_unit)
        }

        // total update
        if (lineItem.markup) {
          lineItem.total = parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit) * parseFloat(lineItem.markup)
        } else {
          lineItem.total = parseFloat(lineItem.quantity) * parseFloat(lineItem.rate_per_unit)
        }
        lineItem.total_display_value = currencyFormattedAmount(lineItem.total)
      });
    }

  }

  refreshLineitem() {
    this.props.refresh()
  }

  render() {
    const {
      material
    } = this.props;
    return (
      <React.Fragment>
        <tr key={material.id}>
          <td className="p-0 border-left-0">
            <ButtonGroup
              fill={true}
              alignText="left"
              large={true}
              minimal
            >
              <ProductServiceAutocomplete
                value={material.product_service}
                onProductSelect={this.onProductSelect.bind(this)}
              />
            </ButtonGroup>
          </td>
          <td className="p-0">
            <ButtonGroup
              fill={true}
              alignText="left"
              large={true}
              minimal
            >
              <SupplierAutocomplete
                value={material.supplier}
                onSupplierSelect={this.onSupplierSelect.bind(this)}
              />
            </ButtonGroup>
          </td>
          <td className="p-0">
            <input
              type='text'
              className='bp3-input bp3-fill'
              placeholder='Part number'
              defaultValue={material.part_number}
              onChange={(event) => this.handlePartNumberChange(event)}
            />
          </td>
          <td className="p-0">
            <input
              type='number'
              className='bp3-input bp3-fill'
              placeholder='Qty'
              defaultValue={parseFloat(material.quantity) || 0}
              onChange={(event) => this.handleHoursChange(event)}
            />
          </td>
          <td className="p-0">
            <div className="bp3-select bp3-fill bp3-large">
              <select onChange={(event) => this.handleUnitChange(event)} value={material.unit}>
                <option value="">N/A</option>
                {
                  Object.keys(UNITS).map((key) => {
                    return (
                      <optgroup label={UNITS[key].label}>
                        {
                          UNITS[key].options.map((option) => {
                            return <option value={option.value}>{option.label}</option>
                          })
                        }
                      </optgroup>
                    )
                  })
                }
              </select>
            </div>
          </td>
          <td className="p-0">
            <input
              type='text'
              className='bp3-input bp3-fill'
              placeholder='Unit Price'
              defaultValue={material.rate_per_unit || ''}
              onChange={(event) => this.handleHourlyRateChange(event)}
            />
          </td>
          <td className="p-0">
            <input
              type='text'
              className='bp3-input bp3-fill'
              placeholder='Markup'
              defaultValue={material.markup || ''}
              onChange={(event) => this.handleMarkupChange(event)}
            />
          </td>
          <td className="py-0" style={{ width: 77 }}>
            <strong>{currencyFormattedAmount(material.total)}</strong>
          </td>
          <td rowSpan="3" vAlign="middle" align="center" className="border-0">
            <Button
              minimal
              type='button'
              icon={"cross"}
              onClick={() => this.handlemMaterialClick({ label: 'Delete' }, material)}
              className="row-delete-btn"
            />
          </td>
        </tr>
        <tr>
          <td colSpan="8" className="p-0 border-left-0">
            <TextArea
              growVertically={true}
              className="bp3-input bp3-fill"
              placeholder="Description"
              defaultValue={material.description}
              onChange={(event) => {
                material.description = event.target.value;
              }}
            />
          </td>
        </tr>

        { material.tax_line_items && material.tax_line_items.length > 0 && material.tax_line_items.map((tax) => {
          return (
            <MaterialTaxItem taxItem={tax} key={tax.id} material={material} refresh={this.refreshLineitem.bind(this)} />
          )
        })
        }

        <div style={{ height: 10 }}></div>

        {/* <tr key={material.id}>

        <tr key={Math.random()}>

          <td colSpan="2" width="18%">
            <ProductServiceAutocomplete
              value={material.product_service}
              onProductSelect={this.onProductSelect.bind(this)} 
            />
          </td>
          <td colSpan="7"></td>
        </tr>
        <tr key={material.id}>
          <td colSpan="2">
            <label className="only-mobile">Description</label>
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Description'
              defaultValue={material.description}
              onChange={(event) => {material.description = event.target.value;}} 
            />
          </td>
          <td >         
            <SupplierAutocomplete
              value={material.supplier}
              onSupplierSelect={this.onSupplierSelect.bind(this)} 
            />
          </td>
          <td>
            <label className="only-mobile">Part Number</label>  
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Part number'
              defaultValue={material.part_number}
              onChange={(event) => this.handlePartNumberChange(event)} 
            />
          </td>
          <td width="12%">
            <label className="only-mobile">Qty</label>
            <input 
              type='number' 
              className='bp3-input bp3-fill' 
              placeholder='Qty'
              defaultValue={parseFloat(material.quantity) || 0}
              onChange={(event) => this.handleHoursChange(event) }
            />  
          </td>
          <td>
            <label className="only-mobile">Unit Price</label>
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Unit Price'
              defaultValue={material.rate_per_unit || ''}
              onChange={(event) => this.handleHourlyRateChange(event) }
            />    
          </td>
          <td>
            <label className="only-mobile">Markup</label>
            <input 
              type='text' 
              className='bp3-input bp3-fill' 
              placeholder='Markup'
              defaultValue={material.markup || ''}
              onChange={(event) => this.handleMarkupChange(event) }
            />
          </td>
          <td>
            <label className="only-mobile">Total</label>
            {currencyFormattedAmount(material.total)}
          </td>
          <td style={{ width: '5%' }}>
            <button 
              className='bp3-button bp3-minimal bp3-icon-trash bp3-small' 
              type='button' 
              onClick={() => this.handlemMaterialClick({ label: 'Delete' }, material)} />
          </td>
          
        </tr> */}
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.isDeleteDialogVisible}
          onCancel={this.handleDeleteCancel.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>Warnning! Deleting this material will not be recoverable.</p>
          <p>Are you sure you want to do this?</p>
        </Alert>
        {/* { material.tax_line_items && material.tax_line_items.length > 0 && material.tax_line_items.map((tax) => {
            return (
              <MaterialTaxItem taxItem={tax} key={tax.id} material={material} refresh={this.refreshLineitem.bind(this)} />
            )
          })
        } */}
      </React.Fragment>
    )
  }

};

export default MaterialItem;