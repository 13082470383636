import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import MaterialItem from './MaterialItem';
import _ from 'lodash';
import { observable } from 'mobx';
import { currencyFormattedAmount, currencySymbol } from 'helpers/currency';
import AddProductForInvoiceModal from 'components/Invoice/AddProductForInvoiceModal';

@inject('store', 'invoiceStore','viewStore','productServiceStore') @observer
class InvoiceMaterialLineItems extends Component {

  componentWillReceiveProps(nextProps) {
    if(nextProps.material) {
      this.calculateSubTotal()
    }
  }

  calculateSubTotal() {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;

    let objects = [];
    if(object && object.attributes && object.attributes.materials) {
      objects = object.attributes.materials;
    }

    if(objects.length > 0) {
      let total = 0;
      total = _.sumBy(objects, function(material) {
                let taxTotal = _.sumBy(material.tax_line_items, function(taxLineItem) {
                              return (taxLineItem.percentage_value/100) * material.total
                          });
                return material.total + taxTotal;
              })
      return currencyFormattedAmount(total)
    } else {
      return currencyFormattedAmount(0)
    }
  }

  handleCloseMaterialForWorkReportDailog() {
    this.props.store.isOpenAddMaterialForInvoiceDialog = false;
  }

  onSuccessAddMaterialForWorkReport(equipment) {
    const {
      productServiceStore: {
        productsServices: {
          objects
        }
      }
    } = this.props;
    this.props.viewStore.productServiceSearch.where.product_service_type = 'Products (Non-inventory)';
    this.props.productServiceStore.loadProductAndServicesForWorkReports(); 
    this.props.store.isOpenAddMaterialForInvoiceDialog = true; 
    this.props.store.productServiceType = 'Products (Non-inventory)'; 
    this.props.store.productServiceForm = 'Invoice';   
  }

  refreshLineItems() {
    this.forceUpdate()
  }

  render() {
    const {
      invoiceStore,
      invoiceStore: {
        showMaterialLineItemsLoader,
        invoice: {
          object,
          object: {
            attributes: {
              materials
            }
          }
        }
      },
      store
    } = this.props;

    let objects = [];
    if(materials && materials.length > 0) {
      objects = materials;
    }

    return (
      <React.Fragment>
        <div className="table-title" style={{ paddingLeft: '10px' }}>
          <h5>
            <strong>Materials <Button icon='add' className="bp3-minimal add-btn" onClick={() => {this.onSuccessAddMaterialForWorkReport()} } >Add Material</Button></strong>
          </h5>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <div style={{ padding: '0px 10px' }} >
            {
              objects && objects.length === 0 && <div className="bp3-callout warning-alert-message bp3-intent-warning bp3-icon-info-sign table-card">
                No material line items have been added to this Invoice.
              </div>
            }
          </div>
          {/* {
            objects && objects.length > 0 && <table className='responsive-table-layout bp3-html-table report-data-table bp3-html-table-striped bp3-interactive table-product-service' style={{ width: '100%' }}> 
              <thead>
                <tr>
                  <th colSpan="2">Products</th>
                  <th>Supplier</th>
                  <th>Part Number</th>
                  <th>Qty</th>
                  <th>Unit Price({currencySymbol()})</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  objects.map((material) => {
                    return (
                      <MaterialItem material={material} key={material.id} refresh={this.refreshLineItems.bind(this)} />
                    )
                  })
                }
              </tbody>
            </table>
          } */}
          {
            objects && objects.length > 0 && <div className="wr-row">
              <div style={{ flex: 1 }}>
                <table className="table table-bordered border-0">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: 215}}>Products</th>
                      <th scope="col" style={{ width: 200 }}>Supplier</th>
                      <th scope="col" style={{ width: 142 }}>Part Number</th>
                      <th scope="col" style={{ width: 140 }}>Qty</th>
                      <th scope="col" style={{ width: 120 }}>Unit</th>
                      <th scope="col" style={{ width: 140 }}>Unit Price({currencySymbol()})</th>
                      <th scope="col">Total</th>
                      <th scope="col" style={{ width: 62 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    
                        {
                          objects.map((material) => {
                            return (
                              <MaterialItem material={material} key={material.id} refresh={this.refreshLineItems.bind(this)} />
                            )
                          })
                        }
                     
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
        {object.attributes.materials.length > 0 && <div className="lineitem_sub_total material_sub_total">
          <p><span style={{ marginRight: 25 }}></span>Sub Total: {this.calculateSubTotal(object.attributes.materials)}</p>
        </div>}
        <AddProductForInvoiceModal
          isOpen={store.isOpenAddMaterialForInvoiceDialog}
          onClose={this.handleCloseMaterialForWorkReportDailog.bind(this)}
          workDetail = {object}
          type={this.props.store.productServiceType}
          formType={this.props.store.productServiceForm}
          onSuccess={this.onSuccessAddMaterialForWorkReport.bind(this)}
          itemType="Materials"
          button_text="Create New Material"
          title="Select Material"
        />
      </React.Fragment>
    )
  }
}
export default InvoiceMaterialLineItems;