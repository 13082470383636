import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { formatTime } from '../../helpers/time';
import { Button, Intent } from '@blueprintjs/core';
import { observable } from 'mobx';
import ManualTransactionModal from './ManualTransactionModal';
import TransactionModal from './TransactionModal';
import CardTransactionModal from './CardTransactionModal';
import SendReceiptDialog from './SendReceiptDialog';
import Toast from "helpers/toaster";

@inject('store', 'invoiceStore', 'paymentStore', 'viewStore') @observer
class PaymentTransactions extends Component {

  @observable showManualTransactionDialog = false;
  @observable showTransactionDialog = false;
  @observable showCardTransactionDialog = false;
  @observable showSendReceiptDialog = false;

  onSuccessCardTransaction() {
    this.showCardTransactionDialog = false;
  }

  onCloseCardTransactionDialog() {
    this.showCardTransactionDialog = false;
  }

  onCloseTransactionDialog() {
    this.showTransactionDialog = false;
  }

  onSuccessTransaction(type) {
    this.showTransactionDialog = false;
    if(type === "cash") {
      this.showManualTransactionDialog = true;
    } else if(type === "card") {
      this.showCardTransactionDialog = true;
    }
  }

  onCloseManualTransactionDialog() {
    this.showManualTransactionDialog = false;
  }

  onSuccessManualTransaction() {
    this.showManualTransactionDialog = false;
  }

  deleteTransaction(transaction) {
    this.props.invoiceStore.deleteTransaction(transaction)
      .then(this.onSuccessfulDeleteTransaction.bind(this))
      .catch(this.onFailDeleteTransaction.bind(this))
  }

  onSuccessfulDeleteTransaction(response) {
    Toast.show({
      icon: "thumbs-up",
      message: "Manual transaction deleted successfully!",
      intent: Intent.SUCCESS
    });
  }

  onFailDeleteTransaction(error) {
    Toast.show({
      icon: "warning-sign",
      message: "Unable to delete manual transaction!",
      intent: Intent.DANGER
    });
  }

  sendReceipt(transaction) {
    this.showSendReceiptDialog = true;
    this.props.invoiceStore.selectedTransaction = transaction;
  }

  render() {
    const {
      invoiceStore: {
        showTransactionLoader,
        invoice,
        invoice: {
          isLoading,
          object: {
            attributes: {
              currency_symbol,
              transactions
            }
          }
        }
      }
    } = this.props;
    return(
      <React.Fragment>
        { !isLoading && <div>
          <div className="table-title" style={{ paddingLeft: '10px' }}>
            <h5>
              <strong>Transactions <Button icon='add' className="bp3-minimal" onClick={() => {this.showTransactionDialog = true}} loading={showTransactionLoader} >Add</Button></strong>
            </h5>
          </div>

          <div className="table-responsive" style={{ marginBottom: '1rem' }}>
            <div style={{ padding: '0px 10px' }} >
              {
                transactions && transactions.length === 0 && <div className="bp3-callout warning-alert-message report-data-table bp3-intent-warning bp3-icon-info-sign table-card">
                  No transactions yet.
                </div>
              }
            </div>
            {
              transactions && transactions.length > 0 && <table className='table table-hover' style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>Transaction Details</th>
                    <th width="20%">Description</th>
                    <th width="20%">Notes</th>
                    <th>Amount Paid</th>
                    <th>Status</th>
                    <th style={{width: 40}}></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    transactions.map((transaction) => {
                      return (
                        <tr key={transaction.id}>
                          <td>
                            <div className="d-flex flex-column">
                              {transaction.transaction_id && <div>Id: {transaction.transaction_id}</div>}
                              {transaction.transaction_date && <div className="sub-text-transcation">Date: {formatTime(transaction.transaction_date)}</div>}
                              {transaction.payment_method && <div className="sub-text-transcation">Method: {transaction.payment_method}</div>}
                            </div>
                          </td>
                          <td width="20%">
                            {transaction.description}
                          </td>
                          <td width="20%">
                            {transaction.notes}
                          </td>
                          <td>
                            {currency_symbol}{transaction.amount_paid}
                          </td>
                          <td>
                            {transaction.status}
                          </td>
                          <td>
                            {
                              <Button 
                                className='bp3-button bp3-minimal bp3-icon-envelope bp3-small'
                                type='button'
                                onClick={() => { this.sendReceipt(transaction) }}
                              />
                            }
                            {
                              transaction.is_manual && <Button 
                                className='bp3-button bp3-minimal bp3-icon-trash bp3-small'
                                type='button'
                                onClick={() => {this.deleteTransaction(transaction)}}
                              />
                            }
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            }
          </div>
          <CardTransactionModal
            isOpen={this.showCardTransactionDialog}
            onClose={this.onCloseCardTransactionDialog.bind(this)}
            onSuccess={this.onSuccessCardTransaction.bind(this)}
          />
          <ManualTransactionModal
            isOpen={this.showManualTransactionDialog}
            onClose={this.onCloseManualTransactionDialog.bind(this)}
            onSuccess={this.onSuccessManualTransaction.bind(this)}
          />
          <TransactionModal
            isOpen={this.showTransactionDialog}
            onClose={this.onCloseTransactionDialog.bind(this)}
            onSuccess={this.onSuccessTransaction.bind(this)}
          />
          <SendReceiptDialog
            isOpen={this.showSendReceiptDialog}
            onClose={() => {
              this.showSendReceiptDialog = false;
              this.props.invoiceStore.selectedTransaction = null;
            }}
          />
        </div>}
      </React.Fragment>
    )
  }

};

export default PaymentTransactions;