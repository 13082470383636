export const isFileTypeSupported = (file) => {
  switch (file.type) {
    case "image":
    case "doc":
    case "docx":
    case "pdf":
    case "xls":
    case "xlsx":
      return true;
    default:
      return false;
  }
};

export const fileExtensionFromUri = (uri) => {
  return fileNameFromUri(uri)
    .split(".")
    .pop();
};

export const fileNameFromUri = (uri) => {
  const fileName = uri
    .substring(uri.lastIndexOf("/") + 1, uri.length);
  return fileName;
};

export const fullSrcFromFile = (file) => {
  if (file.thumbnail) {
    return file.src;
  }
  return thumbnailSrcFromFile(file);
};

export const thumbnailSrcFromFile = (file) => {
  if (file.thumbnail) {
    return file.thumbnail;
  }
  const extension = fileExtensionFromUri(file.src);
  switch (extension) {
    case "xls":
    case "xlsx":
      return require("../images/xls.png");
    case "doc":
    case "docx":
      return require("../images/doc.png");
    default:
      return require("../images/pdf.png");
  }
};

export const thumbnailSrcImageForImage = (asset) => {
  if (asset.thumb.url) {
    return asset.thumb.url;
  }
  const extension = fileExtensionFromUri(asset.url);
  switch (extension) {
    case "xls":
    case "xlsx":
      return require("../images/xls.png");
    case "doc":
    case "docx":
      return require("../images/doc.png");
    case "pdf":
      return require("../images/pdf.png");
    default:
      return require("../images/pdf.png");
  }
};
