import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { 
  Button, Intent
} from "@blueprintjs/core";
import InvoiceHeader from 'components/Invoice/Header';
import InvoiceCustomerDetails from 'components/Invoice/CustomerDetails';
import InvoiceDescription from 'components/Invoice/Description';
import InvoiceLabourLineItems from 'components/Invoice/LabourLineItems';
import InvoiceMaterialLineItems from 'components/Invoice/MaterialLineItems';
import InvoiceCustomLineItems from 'components/Invoice/CustomLineItems';
import PaymentTransactions from 'components/Invoice/PaymentTransactions';
import InvoiceGrandTotal from 'components/Invoice/InvoiceGrandTotal';
import {
  ColumnContainer,
  Column,
  Card
} from 'components/Styles';
import _ from 'lodash';
import Toast from 'helpers/toaster';
import { currencyFormattedAmount, currency } from 'helpers/currency';

@inject('store', 'invoiceStore') @observer
class InvoiceShow extends Component {

  calculateGrandTotal() {
    const {
      invoiceStore: {
        invoice: {
          object
        }
      }
    } = this.props;
    let total = 0.0;
    let amount_paid = 0.0;

    if(object.attributes.labour.length > 0) {   
      total = total + _.sumBy(object.attributes.labour, function(labour) {
        let taxTotal = _.sumBy(labour.tax_line_items, function(taxLineItem) {
                      return (taxLineItem.percentage_value/100) * labour.total
                  });
        return labour.total + taxTotal;   
      })                          
    }

    if(object.attributes.materials.length > 0) {
      total = total + _.sumBy(object.attributes.materials, function(material) {
        let taxTotal = _.sumBy(material.tax_line_items, function(taxLineItem) {
                      return (taxLineItem.percentage_value/100) * material.total
                  });
        return material.total + taxTotal;   
      })
    }

    if(object.attributes.custom_line_items.length > 0) {
      total = total + _.sumBy(object.attributes.custom_line_items, function(line_item) {
        let taxTotal = _.sumBy(line_item.tax_line_items, function(taxLineItem) {
                      return (taxLineItem.percentage_value/100) * line_item.total
                  });
        return line_item.total + taxTotal;   
      })
    }

    // Calculating amount paid
    if(object.attributes.transactions.length > 0) {
      amount_paid = _.sumBy(object.attributes.transactions, function(transaction) {
                      return transaction.status === "paid" ? parseFloat(transaction.amount_paid) : 0.0
                    });
      total = total - amount_paid;
    };
    return currencyFormattedAmount(total)
  }

  updateInvoice() {
    this.props.invoiceStore.update()
      .then(this.onUpdateSuccessfully.bind(this))
      .catch(this.onFailUpdate.bind(this))
  }

  onUpdateSuccessfully(response) {
    Toast.show({ icon: 'thumbs-up', message: 'Updated successfully!', intent: Intent.SUCCESS })
  }

  onFailUpdate(error) {
    Toast.show({ icon: 'warning-sign', message: 'Unable to update!', intent: Intent.DANGER })
  }

  render() {
    const {
      invoiceStore: {
        invoice: {
          object,
          isLoading
        }
      }
    } = this.props;
    return (
      <div className="pdf-view-layout">
        <ColumnContainer>
          <Column>
            <Card>
              {/* Header: Logo + Tenant Details */}
              <InvoiceHeader />

              {/* Customer Details */}
              <InvoiceCustomerDetails selectedTechnician={[{id:object.attributes.created_by.id,label:object.attributes.created_by.full_name,value:object.attributes.created_by.id,selected: true}]} selectedJob={[{id: object.attributes.job.id, label: object.attributes.job.number, value: object.attributes.job.id,selected: true}]}/>

              {/* Reason For Call/Description */}
              <InvoiceDescription />
              
              {/* Labour Line Items */}
              <InvoiceLabourLineItems />

              <div style={{marginTop: 40}}></div>

              {/* Material Line Items */}
              <InvoiceMaterialLineItems />

              <div style={{marginTop: 40}}></div>

              {/* Custom Line Items */}
              <InvoiceCustomLineItems />

              <div style={{marginTop: 10}}></div>

              <PaymentTransactions  /> 
              
              <div style={{marginTop: 40}}></div>

              <InvoiceGrandTotal  /> 

              <div style={{marginTop: 0, clear: 'both'}}></div>
              <br/>
            </Card>
            
            <div className="invoice-save flex-row-reverse">
              <Button 
                style={{float: 'right'}}
                large={true}                
                loading={isLoading} 
                onClick={this.updateInvoice.bind(this)}
              >
                Save
              </Button>
              <div className="lineitem_grand_total" style={{display: 'inline-block'}}>
                <h5 style={{marginBottom: 10}}>Amount Due({currency()}): {this.calculateGrandTotal()}</h5>
              </div>
            </div>
          </Column>
        </ColumnContainer>
      </div>
    )
  }
}

export default InvoiceShow;
