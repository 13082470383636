export const UNITS = {
  area: {
    label: "Area Units",
    options: [
      { value: "sq_ft", label: "Sq. Ft (Square Feet )" },
      { value: "sq_in", label: "Sq. In (Square Inches)" },
      { value: "sq_yd", label: "Sq. Yd (Square Yards)" },
      { value: "acres", label: "Acres" },
      { value: "hectares", label: "Hectares" }
    ]
  },
  length: {
    label: "Length Units",
    options: [
      { value: "lin_ft", label: "Lin. Ft (Linear Feet )" },
      { value: "inches", label: "Inches" },
      { value: "yards", label: "Yards" },
      { value: "meters", label: "Meters" },
      { value: "kilometers", label: "Kilometers" },
      { value: "miles", label: "Miles" }
    ]
  },
  volume: {
    label: "Volume Units",
    options: [
      { value: "cu_ft", label: "Cu. Ft (Cubic Feet )" },
      { value: "cu_in", label: "Cu. In (Cubic Inches)" },
      { value: "cu_yd", label: "Cu. Yd (Cubic Yards)" },
      { value: "gallons", label: "Gallons" },
      { value: "liters", label: "Liters" },
      { value: "barrels", label: "Barrels" }
    ]
  },
  weight: {
    label: "Weight Units",
    options: [
      { value: "pounds", label: "Lb (Pounds)" },
      { value: "ounces", label: "Oz (Ounces)" },
      { value: "tons", label: "Tons" },
      { value: "kilograms", label: "Kg (Kilograms)" },
      { value: "grams", label: "gm (Grams)" }
    ]
  },
  count: {
    label: "Count/Quantity Units",
    options: [
      { value: "each", label: "Each" },
      { value: "pieces", label: "Pieces" },
      { value: "units", label: "Units" },
      { value: "packs", label: "Packs" },
      { value: "boxes", label: "Boxes" }
    ]
  },
  specialty: {
    label: "Specialty Units",
    options: [
      { value: "rolls", label: "Rolls" },
      { value: "bundles", label: "Bundles" },
      { value: "sheets", label: "Sheets" },
      { value: "panels", label: "Panels" },
      { value: "bags", label: "Bags" }
    ]
  }
}