import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable, toJS } from "mobx";
import { Button, Dialog, Classes  } from "@blueprintjs/core";
import InvoiceSignatureModal from './InvoiceSignatureModal';
import { currencyFormattedAmount } from 'helpers/currency';

@inject("paymentStore")
@observer
class PaymentHeader extends Component {
  @observable isPayNowLoading = false;
  @observable isOpenPaymentOptionDialog = false;
  @observable isOpenSignatureModal = false;
  @observable stripePublishableKey = null;

  handleClosePaymentOptionDialog() {
    this.isOpenPaymentOptionDialog = false;
  }

  componentDidMount() {
    const {
      props: {
        paymentStore,
      }
    } = this;
    paymentStore.generateStripeCheckoutSession()
      .then((response) => {
        this.stripePublishableKey = response.data.publishable_key;
      });
  }

  async redirectToStripeCheckout() {
    this.isPayNowLoading = true;
    const {
      props: {
        paymentStore,
      }
    } = this;
    paymentStore.generateStripeCheckoutSession()
      .then((response) => {
        let stripeSession = response.data;
        var customerStripe = window.Stripe(stripeSession.publishable_key);
        customerStripe.redirectToCheckout({
          sessionId: stripeSession.session.id
        }).then((result) => {
          console.log('redirected!')
        })
      })
      .catch((error) => {
        if(error.response && error.response.data && error.response.data.errors) {
          alert(error.response.data.errors);
        } else {
          console.log(error)
          alert("Something went wrong")
        }
      })
      .finally(() => {
        this.isPayNowLoading = false;
      })
  }

  startPayProcess() {
    const {
      paymentStore: {
        preference,
        invoice: { object }
      }
    } = this.props;

    if(object.attributes && object.attributes.enable_invoice_signature) {
      this.isOpenSignatureModal = true;
    } else {
      if(preference && preference.object && preference.object.payment_gateway === "stripe_connect") {
        this.redirectToStripeCheckout();
      }
    }
  }

  handleCloseInvoiceSignatureDailog() {
    this.isOpenSignatureModal = false;
  }

  onSuccessInvoiceSignature() {
    this.isOpenSignatureModal = false;
    this.redirectToStripeCheckout();
  }

  render() {
    let {
      paymentStore: {
        preference,
        due_amount,
        invoice: { object }
      }
    } = this.props;
    const isPaid = object.attributes.payment_status === "paid";
    return (
      <div>
        <div className="payment-header">
          <div>
            <h4>
              Request for Payment from{" "}
              {object && object.attributes && object.attributes.tenant.name}
            </h4>
          </div>
          <div>
            <Button text="Print" icon="print" style={{ marginRight: 15 }} onClick={() => window.print()} />
            {/* <Button text="Download" icon="import" style={{marginRight:15}} /> */}
            {/* <Button text="Pay Now" icon="credit-card" intent="primary" onClick={() => { this.isOpenPaymentOptionDialog = true }}> <strong>${due_amount}</strong></Button> */}
            
            {this.stripePublishableKey && preference && preference.object && preference.object.payment_gateway === "stripe_connect" && <Button
              loading={this.isPayNowLoading}
              disabled={isPaid}
              icon="credit-card"
              intent="primary"
              onClick={this.startPayProcess.bind(this)}> 
                {
                  isPaid ? "Already Paid" : `Pay Now ${currencyFormattedAmount(due_amount)}`
                }
            </Button>}
          </div>
        </div>
        {
          isPaid &&
          <div className="bp3-callout bp3-intent-success bp3-icon-tick ">
            <h4 className="bp3-heading">Invoice Paid.</h4>
            Thank you for your business.
          </div>
        }
        {/* <div className="payment-header-wrapper">
          <div className="payment-header-details">
            Invoice {object && object.attributes && object.attributes.number}
          </div>
          <div className="payment-header-separator"></div>
          <div className="payment-header-details">
            Amount due: ${due_amount}
          </div>
          <div className="payment-header-separator"></div>
          <div className="payment-header-details">
            Due on: {moment(object.attributes.invoice_due_date).format("ll")}
          </div>
        </div> */}

        <div className="payment-modal">
          <Dialog
            title="Select Payment Method"
            isOpen={this.isOpenPaymentOptionDialog}
            isCloseButtonShown={true}
            onClose={this.handleClosePaymentOptionDialog.bind(this)}
          >
            <div className={Classes.DIALOG_BODY}>
              <div className="modal-payment-option">
                <ul className="list-unstyled">
                  <li>
                    <div className="modal-payment-block">
                      <div style={{marginRight:10}}><img src="/mastercard.svg" /></div>
                      <div>Credit / Debit Card</div>
                    </div>
                  </li>
                  <li>
                    <div className="modal-payment-block">
                      <div style={{ marginRight: 10 }}><img src="/interac-eTransfer.svg" /></div>
                      <div>Interac eTransfer / Cash</div>
                    </div>
                  </li>
                </ul>

              {/* Screen Two */}

                <div className="modal-payment-block">
                  <div style={{ marginRight: 10 }}><img src="/mastercard.svg" /></div>
                  <div>Credit / Debit Card</div>
                </div>

                <div className="modal-payment-form mt-5">
                  <input type="text" class="bp3-input mb-3 bp3-fill" placeholder="Name On Card" />
                  <input type="text" class="bp3-input mb-3 bp3-fill" placeholder="Card Number" />
                  <div style={{display:"flex", alignItems:"center", justifyContent:'space-between'}}>
                    <div style={{width:"45%"}}><input type="text" class="bp3-input mb-3 bp3-fill" placeholder="DD / MM" /></div>
                    <div style={{ width: "45%"}}><input type="text" class="bp3-input mb-3 bp3-fill" placeholder="CVV" /></div>
                  </div>
                  <Button text="SCAN CARD" fill="true" style={{backgroundColor:"#000", color:"#fff", fontWeight:"600", backgroundImage:"none"}} />
                  <Button fill="true" style={{ backgroundColor: "", color: "", fontWeight: "600", backgroundImage: "none", marginTop:"3rem" }} >PAY - {currencyFormattedAmount(due_amount)}</Button>
                </div>

                {/* Screen Three */}

                <div className="modal-payment-block">
                  <div style={{ marginRight: 10 }}><img src="/interac-eTransfer.svg" /></div>
                  <div>Interac eTransfer / Cash</div>
                </div>

                <div className="modal-payment-form mt-5">
                  <input type="text" class="bp3-input mb-3 bp3-fill" placeholder="Amount" />
                  <input type="text" class="bp3-input mb-3 bp3-fill" placeholder="Date/Time" />                
                  <textarea rows="4" class="bp3-input mb-3 bp3-fill" placeholder="Notes" style={{borderRadius:3}}></textarea>
                  <Button fill="true" style={{ backgroundColor: "", color: "", fontWeight: "600", backgroundImage: "none", marginTop: "3rem" }} >PAY - {currencyFormattedAmount(due_amount)}</Button>
                </div>

              </div>
            </div>
          </Dialog>
        </div>
        <InvoiceSignatureModal
          isOpen={this.isOpenSignatureModal}
          onClose={this.handleCloseInvoiceSignatureDailog.bind(this)}
          onSuccess={this.onSuccessInvoiceSignature.bind(this)}
        />
      </div>
    );
  }
}

export default PaymentHeader;
