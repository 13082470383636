import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import MaterialItem from './MaterialItem';
import { observable } from 'mobx';
import _ from 'lodash';
import { currencyFormattedAmount, currencySymbol } from 'helpers/currency';
import AddProductForEstimateModal from 'components/Quote/AddProductForEstimateModal';

@inject('store', 'viewStore', 'quoteStore', 'productServiceStore') @observer
class QuoteMaterialLineItems extends Component {

  calculateSubTotal(materials) {
    if (materials.length > 0) {
      let total = 0;
      total = _.sumBy(materials, function (material) {
        let taxTotal = _.sumBy(material.tax_line_items, function (taxLineItem) {
          return (taxLineItem.percentage_value / 100) * material.total
        });
        return material.total + taxTotal;
      })
      return currencyFormattedAmount(total)
    } else {
      return currencyFormattedAmount(0)
    }
  }

  calculateSubTotalBeforeMarkup(materials) {
    if (materials.length > 0) {
      var sum = 0;
      _.each(materials, function (material) {
        sum += parseFloat(material.quantity) * parseFloat(material.rate_per_unit)
      })
      return currencyFormattedAmount(sum)
    } else {
      return currencyFormattedAmount(0)
    }
  }

  handleCloseMaterialForWorkReportDailog() {
    this.props.store.isOpenAddMaterialForQuoteDialog = false;
  }

  onSuccessAddMaterialForWorkReport(quote) {
    const {
      productServiceStore: {
        productsServices: {
          objects
        }
      }
    } = this.props;
    this.props.viewStore.productServiceSearch.where.product_service_type = 'Products (Non-inventory)';
    this.props.productServiceStore.loadProductAndServicesForWorkReports();
    this.props.store.isOpenAddMaterialForQuoteDialog = true;
    this.props.store.productServiceType = 'Products (Non-inventory)';
    this.props.store.productServiceForm = 'Quote';
  }

  refreshLineItems() {
    this.forceUpdate()
  }

  render() {
    const {
      quoteStore,
      quoteStore: {
        quote: {
          object
        },
        showMaterialLineItemsLoader
      },
      store
    } = this.props;
    return (
      <React.Fragment>
        <div className="table-title" style={{ paddingLeft: '10px' }}>
          <h5>
            <strong>Materials <Button icon='add' className="add-btn bp3-minimal" onClick={() => { this.onSuccessAddMaterialForWorkReport() }} >Add Material</Button></strong>
          </h5>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <div style={{ padding: '0px 10px' }} >
            {
              object.attributes.materials.length === 0 && <div className="bp3-callout warning-alert-message bp3-intent-warning bp3-icon-info-sign table-card">
                No material line items have been added to this Work Order.
              </div>
            }
          </div>

          {
            object.attributes.materials.length > 0 && <div className="wr-row">
              <div style={{ flex: 1 }}>
                <table className="table table-bordered border-0">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: 188 }}>Products</th>
                      <th scope="col" style={{ width: 172 }}>Supplier</th>
                      <th scope="col" style={{ width: 123 }}>Part Number</th>
                      <th scope="col" style={{ width: 120 }}>Qty</th>
                      <th scope="col" style={{ width: 120 }}>Unit</th>
                      <th scope="col" style={{ width: 120 }}>Unit Price({currencySymbol()})</th>
                      <th scope="col" style={{ width: 130 }}>Markup</th>
                      <th scope="col">Total</th>
                      <th scope="col" style={{ width: 62 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      object.attributes.materials.map((material) => {
                        return (
                          <MaterialItem material={material} key={material.id} refresh={this.refreshLineItems.bind(this)} />
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
        {object.attributes.materials.length > 0 && <div className="lineitem_sub_total material_sub_total">
          <p><span style={{ marginRight: 25 }}>Sub Total Before markup: {this.calculateSubTotalBeforeMarkup(object.attributes.materials)}</span>Sub Total: {this.calculateSubTotal(object.attributes.materials)}</p>
        </div>}
        <AddProductForEstimateModal
          isOpen={store.isOpenAddMaterialForQuoteDialog}
          onClose={this.handleCloseMaterialForWorkReportDailog.bind(this)}
          workDetail={object}
          type={this.props.store.productServiceType}
          formType={this.props.store.productServiceForm}
          onSuccess={this.onSuccessAddMaterialForWorkReport.bind(this)}
          itemType="Materials"
          button_text="Create New Material"
          title="Select Material"
        />
      </React.Fragment>
    )
  }
}
export default QuoteMaterialLineItems;